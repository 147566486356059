@use 'sass:math';
@import '../../../../../../styles/variables';

.cure-AppPreview {
  display: flex;
  padding: 0;
  grid-gap: m(2);
  align-items: center;

  @include maxWidth('xx-large') {
    grid-gap: m(math.div(5,3));
    padding: 0 m(math.div(4,3));
  }

  @include maxWidth('medium') {
    padding: 0 m(math.div(1,3)) 0 0;
  }

  @include maxWidth('small') {
    padding: 0;
    flex-direction: column;
    grid-gap: 0;
  }
}

.cure-AppPreview.is-right {
  flex-direction: row-reverse;

  @include maxWidth('small') {
    flex-direction: column;
  }
}

.cure-AppPreviewImage {
  @include maxWidth('small') {
    width: 100%;
  }
}

.cure-AppPreviewImage > img {
  max-width: 33rem;
  max-height: 20rem;
  width: 100%;
  box-shadow: 0 10px 15px rgba(0,0,0,.25);
  border-radius: .15rem;

  @include maxWidth('large') {
    max-width: 28rem;
  }

  @include maxWidth('small') {
    height: 6rem;
    width: 100%;
    object-fit: cover;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }
}

.cure-AppPreviewBody {
  max-width: 35rem;
  min-width: 25rem;
  padding:  0;
  display: flex;
  flex-direction: column;
  flex: 1;

  @include maxWidth('small') {
    min-width: auto;
    margin-top: 1rem;
  }
}

.cure-AppPreview.is-right .cure-AppPreviewBody {
  align-items: flex-end;

  @include maxWidth('small') {
    align-items: flex-start;
  }
}

.cure-AppPreviewTitle {
  margin: 0 0 m(math.div(2,3));
  font-family: "Raleway" ,"Roboto", sans-serif;
  font-size: $a3;

  @include maxWidth('xx-large') {
    font-size: $b3;
  }

  @include maxWidth('medium') {
    font-size: $a2;
    margin: 0 0 m(math.div(1,3)); ;
  }
}

.cure-AppPreviewTags {
  margin: 0 0 m(math.div(2,3));

  @include maxWidth('medium') {
    margin: 0 0 m(math.div(1,3)); ;
  }
}

.cure-AppPreview.is-right .cure-AppPreviewTitle {
  text-align: right;

  @include maxWidth('small') {
    text-align: left;
  }
}

.cure-AppPreviewDescription {
  color: $secondaryTextColor;
}

.cure-AppPreview.is-right .cure-AppPreviewDescription {
  text-align: right;

  @include maxWidth('small') {
    text-align: left;
  }
}

.cure-AppPreview.is-right .cure-AppPreviewTags {
  justify-content: flex-end;

  @include maxWidth('small') {
    justify-content: flex-start;
  }
}

.cure-AppPreviewTools {
  margin-top: m(math.div(2,3));
  display: flex;
  grid-gap: 1rem;
  flex-wrap: wrap;

  @include maxWidth('x-large') {
    margin-top: m(math.div(1,3));
  }

  @include maxWidth('medium') {
    margin-top: 0;
  }
}

.cure-AppPreviewTools > .ptr-button {
  height: 2rem;
  min-width: fit-content;
}

.cure-AppPreviewTools > .ptr-button.download {
  color: var(--base90);
}