@use 'sass:math';
@import '../../../../styles/variables';

.cure-App2_ShowcaseC {
  align-items: center;
  grid-gap: m(1);
}

.cure-App2_ShowcaseC-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cure-App2_ShowcaseC-title > h2 {
  text-align: center;
  margin-bottom: .75rem;
}

.cure-App2_ShowcaseC-title > p {
  color: var(--base65);
  margin-bottom: 0;
}


.cure-App2_ShowcaseC-chart-wrapper {
  flex: 1;
  padding: 0 m(2) m(2);
  overflow: hidden;
  width: 100%;
}