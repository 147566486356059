@use 'sass:math';
@import '../../../../../../styles/variables';

.cure-CityInfoPanelBtn {
	position: absolute;
	width: 1.8rem;
	height: 3rem;
	padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-left: 0;
    border-radius: 0px 5px 5px 0px;
	left: 25rem;
	top: calc(50% - 3rem);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 99;
	transition: 0.4s;
	background-color: rgba(131, 131, 131, 0.425);
	@include maxWidth('small') {
		display: none;
	  }
}

.cure-CityInfoPanelBtn .ptr-icon {
	transform: rotate(180deg);
	margin: calc(0.375rem - 1px) -0.2rem;
	transition: 0.4s;
	@include maxWidth('small') {
		transform: rotate(90deg);
		padding: 0;
		margin: 0.9rem 0 0 0.9rem;
	  }
}

.cure-CityInfoPanelBtn.is-closed {
	left: 0;
	@include maxWidth('small') {
		left: 50%;
		bottom: 0;
	  }
}

.cure-CityInfoPanelBtn.is-closed .ptr-icon  {
	transform: rotate(0);
	@include maxWidth('small') {
		transform: rotate(270deg)
	  }
}