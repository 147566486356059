@use 'sass:math';
@import '../../../styles/variables';

.cure-Story-mainPanel-footer {
	width: 100%;
	height: inherit;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@include maxWidth('medium') {
		justify-content: start;
	  }
}

.cure-Story-mainPanel-footerSection {
	width: 80%;
	padding: m(1) 0 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid var(--base35);
}

.cure-Story-mainPanel-footerSection > p {
	text-align: center;
	color: $secondaryTextColor;
}

.cure-Story-mainPanel-footerSection.is-last {
	border: none;
}

.cure-Story-mainPanel-footerSection .ptr-StoryParagraph {
	margin: 0 1rem;
	max-width: 50rem;
}

.cure-Story-mainPanel-orderServiceNote {
	color: var(--accent60);
}