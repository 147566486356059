@use 'sass:math';
@import '../../../../styles/variables';

.cure-StoryMainPanelFooter-dataDownload {
	max-width: 20rem;
}

.cure-dataDownload-selector {
	min-width: 15rem;
	border: 1px solid var(--base40);
	box-shadow: 0 2px 5px rgb(0 0 0 / 35%);
	border-radius: 0.2rem;
}

.cure-dataDownload-selector:hover {
	border-color: var(--base60);
}

.cure-dataDownload-selector.is-expanded {
	border-color: var(--accent50);
}

.cure-dataDownload-selectorHeader {
	padding-left: 0.8rem;
}

.cure-dataDownload-selectorHeader div div {
	font-weight: 500;
	color: var(--base80);
}

.cure-dataDownload-selectorBody {
	min-width: 15rem;
	border-radius: 0.2rem;
	background-color: var(--base10);
}

.cure-dataDownload-selectorBody.is-expanded {
	max-height: 20rem;
}

.cure-dataDownload-selectorBody-link {
	height: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.8rem;
	cursor: pointer;
	font-weight: 500;
	color: var(--base80);
	text-decoration: none;
}

.cure-dataDownload-selectorBody-link:hover {
	background-color: var(--accent30);
	color: var(--base80);
}

.cure-dataDownload-selectorBody-link.is-disabled {
	color: var(--base50);
	cursor: default;
}

.cure-dataDownload-selectorBody-link.is-disabled:hover {
	background-color: transparent;
}