@use 'sass:math';
@import '../../../styles/variables';

.cure-Showcase {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cure-NoDataInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: $b2;
  font-style: italic;
  color: var(--base50);
  background: var(--base0);
}