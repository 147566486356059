@use 'sass:math';
@import '../../../../styles/variables';

.cure-App1_ShowcaseB > .ptr-timeline {
  height: 5rem;
}

.cure-App1_ShowcaseB .cure-LegendPositionWrapper{
  position: absolute;
  top: 0;
  width: 2rem;
  bottom: 6rem;
  right: 0.7rem;
  display: flex;
  align-items: end;
  z-index: 2;
}

.cure-App1_ShowcaseB .cure-MapLegends.is-left {
  left: 0.7rem;
}