@use 'sass:math';
@import '../../../../styles/variables';

.cure-UrbanChallenges .cure-Hero {
  background-image: url('../../../../assets/img/city_big.webp');
  background-size: cover;
}

.cure-UrbanChallenges-content {
  margin: 0 auto m(3);
  max-width: 50rem;
  position: relative;
  z-index: 1;

  @include maxWidth('medium') {
    padding: 0 m(math.div(4,3)) 0 m(math.div(2,3));
    margin: 0 auto 2rem;
  }
}

.cure-UrbanChallenges h1 {
  @include minWidth('large') {
    font-size: $a4;
  }
}

.cure-UrbanChallenges h2 {
  font-size: $b3;

  @include maxWidth('large') {
    font-size: $a2;
  }
  @include maxWidth('medium') {
    font-size: $b2;
  }
}

.cure-UrbanChallenges ul {
  margin-left: 1rem;
}

.cure-UrbanChallenges a {
  font-weight: bold;
  background-color: var(--accent10);
}

.cure-UrbanChallenges p,
.cure-UrbanChallenges ul li {
  font-size: $a1;
  color: $secondaryTextColor;
}

