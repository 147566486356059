@use 'sass:math';
@import '../../../../../styles/variables';

// Title
.cure-MapLegendTitle {
  line-height: 1;
}

.cure-MapLegendTitle-name {
  font-weight: bold;
  font-size: $b1;
  color: $baseTextColor;
}

.cure-MapLegendTitle-unit {
  font-size: $b1;
  margin-left: .25rem;
  color: $secondaryTextColor;
}

// Content
.cure-MapLegendContent {
  display: flex;
  margin-top: .35rem;
  grid-gap: 1rem;
  padding-right: .25rem;
  width: 100%;
  align-items: center;
}

.cure-MapLegendContent.is-expanded {
  flex-direction: column;
  grid-gap: .5rem;
  align-items: flex-start;
}

// Class item
.cure-MapLegendClassItem,
.cure-MapLegendValueItem {
  display: flex;
  grid-gap: .35rem;
}

.cure-MapLegendClassItem > div {
  width: 1.25rem;
  height: .75rem;
}

.cure-MapLegendValueItem > div {
  width: .75rem;
  height: .75rem;
  border-radius: .5rem;
}

.cure-MapLegendClassItem > span,
.cure-MapLegendValueItem > span {
  font-size: $b1;
  line-height: 1;
  color: $secondaryTextColor;
}

// Classes Bar
.cure-MapLegendClassesBar {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
}

.cure-MapLegendClassesBar-label {
  font-size: $b1;
  line-height: 1;
  color: $secondaryTextColor;
}

.cure-MapLegendClassesBar-bar {
  display: flex;
  flex: 1;
  margin: 0 .25rem;
  height: 100%;
}

.cure-MapLegendClassesBar-bar > div {
  flex: 1;
  height: .75rem;
}

.cure-MapLegendClassesBar .cure-MapLegendClassItem {
  margin-right: .75rem;
}

// Attribute classes
.cure-MapLegend-AttributeClasses {
  display: flex;
  flex: 1;
}

.cure-MapLegend-AttributeClasses.is-expanded {
  flex-direction: column;
  grid-gap: .25rem;
}

// Attribute values
.cure-MapLegend-AttributeValues {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  grid-gap: .35rem;
}

.cure-MapLegend-AttributeValues.is-expanded {
  flex-direction: column;
}

// Attribute scale
.cure-MapLegend-AttributeScale {
  display: flex;
  flex: 1;
}

.cure-MapLegend-AttributeScale.is-expanded {
  width: 100%;
}

.cure-MapLegend-AttributeScale > span {
  font-size: $b1;
  line-height: 1;
  color: $secondaryTextColor;
}

.cure-MapLegend-AttributeScale > div {
  flex: 1;
  height: .75rem;
  margin: 0 .25rem;
}

