@use 'sass:math';
@import '../../../../styles/variables';

.cure-Services-container {
	width: 100%;
	height: 100%;
	min-height: 25rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem;
}

.cure-Services-servicesBtnContainer {
	max-width: 100rem;
	width: 100%;
	height: 3.5rem;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.cure-Services-servicesBtn {
	position: relative;
	width: 10rem;
	height: 85%;
	background-color: var(--base0);
	text-align: center;
	border: 1px solid var(--base30);
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: none;

	box-shadow: $floatingElementShadow;
}

.cure-Services-servicesBtn:hover {
	background-color: var(--base20);
}


.cure-Services-servicesBtnLabel {
	font-weight: 600;
	font-size: $a1;
	margin-left: 0.5rem;
}

.cure-Services-Table {
	max-width: 100rem;
	height: calc(100% - 3.5rem);
	width: 100%;
	padding-bottom: 3.5rem;
	border: 1px solid rgba(0, 0, 0, 0.145);
	box-shadow: $floatingElementShadow;
}

.cure-ManageServices-container input[type=date],
.cure-ManageServices-container input[type=number],
.cure-ManageServices-container input[type=text] {
	color: var(--base50);
	line-height: 1.7rem;
    font-size: 0.875rem;
    border-color: var(--base40);
    border-radius: 4px;
    border-style: solid;
    background: transparent;
    border-width: 1px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 35%);
	padding: 0 6px;
	margin-right: 0.5rem;

	&:invalid{
		border-color: red;
	}
}