@use 'sass:math';
@import '../../../../styles/variables';

.cure-App8_ShowcaseB-infoContent {
	padding: 0.5rem;
}

.cure-App8_ShowcaseB-infoTitle {
	margin: 0 0 0.5rem 0;
	@include minWidth('xxx-large') {
		font-size: $a3;
	}
	@include minWidth('large') {
		font-size: $b2;
	}
	@include maxWidth('medium') {
		font-size: $a1;
	}
	@include maxWidth('x-small') {
		font-size: $b1;
	}
}

.cure-App8_ShowcaseB-infoDescription {
	margin: 0;
}

.cure-App8_ShowcaseB-headlineTitle {
	max-width: none;
	text-align: center;
	margin: 0 0.5rem 0 0;
	@include minWidth('xxx-large') {
		font-size: $a4;
	}
	@include minWidth('large') {
		font-size: $b3;
	}
	@include maxWidth('medium') {
		font-size: $b2;
	}
	@include maxWidth('x-small') {
		font-size: $a1;
	}
}

.cure-App8_ShowcaseB-Table {
	width: 100%;
	height: 100%;
	overflow: auto;
	border: 1px solid rgba(0, 0, 0, 0.145);
}