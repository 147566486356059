@use 'sass:math';
@import '../../../../styles/variables';

.cure-MapLegends {
  position: absolute;
  bottom: 3.2rem;
  right: .7rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.cure-MapLegends.is-left {
  right: auto;
  left: .7rem
}

// components
.cure-ColorClasses {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cure-ColorClassesBar {
  height: .5rem;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cure-ColorClassesBarItem {
  flex: 1;
}

.cure-ColorClassesValues {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: .1rem 0 0 .3rem;
}

.cure-ColorClassesValues.is-firstAndLast {
  justify-content: space-between;
}

.cure-ColorClassesValues.without-unit .cure-ColorClassesValue:last-child {
  padding: 0;
  flex: 0.5;
}

.cure-ColorClassesValue,
.cure-ColorClassesValuesUnit {
  flex: 1;
  font-size: $b1;
  color: var(--base50);
  text-align: center;
  padding-left: .3rem;
  display: flex;
}

.cure-ColorClassesValue:last-child {
  padding-left: 0;
  flex: 0;
}

.cure-ColorClassesValues.is-firstAndLast .cure-ColorClassesValue {
  flex: 0;
}

.cure-ColorClassesValuesUnit {
  font-size: $b1;
  color: var(--base50);
}