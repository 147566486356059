@import '../../../../styles/variables';

.cure-MapSetInfo > .cure-MapAttribution {
  z-index: 2;
  position: relative;
  font-size: $a0;
  color: var(--base50);
  padding: .1rem .35rem;
}

.cure-MapAttribution a {
  color: var(--base50);
}