@use 'sass:math';
@import '../../../styles/variables';

.cure-InfoWindow {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 100%;
}

.cure-InfoWindow-Btn:hover {
	color: var(--accent55);
}

.cure-InfoWindow-window {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: absolute;
	width: 18rem;
	max-height: 30rem;
	height: 30rem;
	overflow: auto;
	transition-property: height;
	transition-duration: 0.3s;
	padding: 0 0 3px 3px;
	margin-top: 2.3rem;
	z-index: 3;
	@include minWidth('xxx-large') {
		max-height: 35rem;
		width: 22rem;
	}
	@include maxWidth('medium') {
		max-height: 15rem;
		width: 14rem;
	}
}

.cure-InfoWindow-windowConnect {
	width: 1px; 
	height: 1px; 
	border-left: 18px solid transparent;
	border-right: 18px solid transparent;
	border-bottom: 22px solid var(--base10);
}

.cure-InfoWindow-windowContent {
	overflow: auto;
	position: relative;
	padding: 1rem;
	background-color: var(--base10);
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.201);
}