@use 'sass:math';
@import '../../../../styles/variables';

// Map slider
.ptr-MapCompareSlider {
  height: 100%;
  background-image:  linear-gradient(var(--base20) 1px, transparent 1px), linear-gradient(to right, var(--base20) 1px, transparent 1px);
  background-size: 20px 20px;
}

.ptr-MapCompareSlider .ptr-map-controls-wrapper {
  z-index: auto;
}

div[data-rcs="handle-container"] {
  z-index: 3;
}

div[data-rcs="clip-item"] {
  z-index: 2;
}