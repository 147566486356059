@use 'sass:math';
@import '../../../../styles/variables';

.cure-App7_ShowcaseA .cure-TagSelect {
    max-width: 14rem;
}
.cure-App7_ShowcaseA .top-bar{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.cure-App7_ShowcaseA .cure-ParametersSelect {
    width: unset;
}