@import './variables';

// 
// Timeline
// 
g.ptr-timeline-day,
g.levels .ptr-timeline-day > line {
	stroke: var(--accent40);
}

g.ptr-timeline-month,
g.levels .ptr-timeline-month > line {
	stroke: var(--accent40);
}

text.ptr-timeline-year-label,
text.ptr-timeline-day-label,
text.ptr-timeline-month-label {
  fill: var(--accent50);
}


// 
// Set firs child fill same like backgroud to pretend disappear
// 
.ptr-timeline-content .levels>.ptr-timeline-day .ptr-timeline-day-label {
  fill: var(--base10);
}
.ptr-timeline-content .levels>.ptr-timeline-day~.ptr-timeline-day .ptr-timeline-day-label{
  fill: var(--accent50);
}