@use 'sass:math';
@import '../../../styles/variables';

.cure-ShowcaseHead {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 1.5rem 1rem 1rem 1rem;
}

.cure-ShowcaseHead-headline {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.cure-ShowcaseHead-headlineTitle {
	max-width: none;
	text-align: center;
	margin: 0;
	@include minWidth('xxx-large') {
		font-size: $a4;
	}
	@include minWidth('large') {
		font-size: $b3;
	}
	@include maxWidth('medium') {
		font-size: $b2;
	}
	@include maxWidth('x-small') {
		font-size: $a1;
	}
}

.cure-ShowcaseHead-selectsContainer {
	z-index: 2;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-flow: wrap;
}

.cure-ShowcaseHead-selectContainer {
	display: flex;
	flex-direction: row;
	margin: 1rem 2rem 0 2rem;
	justify-content: center;
	align-items: center;
	@include minWidth('xxx-large') {
		margin: 1.5rem 3rem 0 3rem;
	}
	@include maxWidth('large') {
		margin: 0.8rem 1rem 0 1rem;
	}
	@include maxWidth('x-small') {
		margin: 0.5rem 0.5rem 0 0.5rem;
	}
}

.cure-ShowcaseHead-selectLabel {
	font-size: $a1;
	font-weight: 600;
	margin: 0 0.5rem 0 0;
	text-align: center;
	min-width: max-content;
	@include minWidth('xxx-large') {
		font-size: $b2;
	}
	@include maxWidth('x-small') {
		font-size: $b1;
	}
}

.cure-ShowcaseHead-select {
	width: 13rem;
	@include minWidth('xxx-large') {
		width: 15rem;
	}
	@include maxWidth('large') {
		width: 11rem;
	}
	@include maxWidth('x-small') {
		width: 8rem;
	}
}