@use 'sass:math';
@import '../../../../../styles/variables';

.cure-StyleBasedLegend {
  background: var(--base05);
  border-radius: .25rem;
  box-shadow: $floatingElementShadow;
  width: 20rem;

  display: flex;
  flex-direction: column;
  padding: .35rem .5rem .5rem .35rem;
  &.expandable{
    cursor: pointer;
  }
}

.cure-StyleBasedLegend.is-expanded {
  padding: .5rem .75rem .75rem .5rem;
}

