@use 'sass:math';
@import '../../../../../styles/variables';

.cure-ManageServices-modalWindow {
	width: 80%;
	height: 80%;
	padding: 2rem;
}

.cure-ManageServices-modalWindowContent {
	width: 100%;
	height: 100%;
}

.cure-ManageServices-container {
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.cure-ManageServices-startBtn {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	width: 13rem;
	height: 2.5rem;
}

.cure-ManageServices-apContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 1rem;
	background-color: rgba(207, 207, 207, 0.342);
	border-radius: 10px;
	border: 2px solid rgb(182, 182, 182);
}

.cure-ManageServices-apHead {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	z-index: 2;
}

.cure-ManageServices-apTitle {
	margin: 0;
}

.cure-ManageServices-openServiceBtnWrapper {
	display: flex;
	align-items: center;
	min-width: fit-content;
}

.cure-ManageServices-openServiceBtn {
	display: flex;
	flex-direction: row;
}

.cure-ManageServices-openServiceIcon {
	display: flex;
	align-items: center;
	transition: 0.3s;
}

.cure-ManageServices-apSelects {
	overflow: auto;
	z-index: 3;
	height: 0rem;
	margin-top: 0rem;
	margin: 0 0 0 1rem;
	padding: 0rem;
	width: calc(100% - 1rem);
	display: flex;
	flex-direction: column;
	justify-content: center;
	transition: 0.1s;
}

.cure-ManageServices-apSelectContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: 1rem;
}

.cure-ManageServices-apSelectTitle {
	margin: 0 0.5rem 0 0;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
}

.cure-ManageServices-apSelect {
	width: 12rem;
}

.cure-ManageServices-apStartBtnWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.cure-ManageServices-apDefaultContainer { //
	width: 100%;
	height: 10rem;
}

.cure-ManageServices-note{
	
		color: var(--base50);
		font-size: $a1;
		margin-top: 0.1rem;
		white-space: break-spaces;
	span{
		color: red;
		margin-right: .5rem;
	}
}