@use 'sass:math';
@import '../../../../styles/variables';

.cure-App4_ShowcaseA {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cure-App4_TagSelect {
margin-left: .5rem;
}