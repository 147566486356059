@use 'sass:math';
@import '../../../../styles/variables';

$mapBorder: 0.05rem solid var(--base30); // the width in rem fixes the ugly white space between border and content in Chrome

.ptr-MapSetContainer {
  flex: 1;
  background-image:  linear-gradient(var(--base20) 1px, transparent 1px), linear-gradient(to right, var(--base20) 1px, transparent 1px);
  background-size: 20px 20px;
  position: relative;
}

.ptr-MapSetContainer .ptr-map-set {
  height: 100%;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell.row1 .ptr-map {
  border-top: 0;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell.col1 .ptr-map {
  border-left: 0;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
  border-top: $mapBorder;
}

.ptr-MapSetContainer .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
  border-left: $mapBorder;
}