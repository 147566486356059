@use 'sass:math';
@import '../../../../styles/variables';

.cure-TagSelect{
    margin-left: 0.5rem;
    max-width: 20rem;
    min-width: 10rem;
    flex: 1 1 auto;
}

.cure-TagSelect > span {
    display: block;
    margin: .25rem 0 -.25rem .5rem;
    font-size: $b1;
    color: $baseTextColor;
}