@use 'sass:math';
@import '../../../../styles/variables';

.cure-App11_ShowcaseA-infoContent {
	padding-left: 1rem;
}

.cure-App11_ShowcaseA-infoTitle {
	margin: 0 0 0.5rem 0;
	@include minWidth('xxx-large') {
		font-size: $a3;
	}
	@include minWidth('large') {
		font-size: $b2;
	}
	@include maxWidth('medium') {
		font-size: $a1;
	}
	@include maxWidth('x-small') {
		font-size: $b1;
	}
}

.cure-App11_ShowcaseA-headline {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-right: 0.5rem;
}

.cure-App11_ShowcaseA-mainHeading {
	max-width: none;
	text-align: center;
	margin-bottom: 0.3rem;
	@include minWidth('medium') {
		font-size: $b3;
	}
	@include minWidth('xxx-large') {
		font-size: $a4;
		margin-bottom: 0.4rem;
	}
	@include maxWidth('medium') {
		font-size: $a2;
	}
	@include maxWidth('x-small') {
		font-size: $b2;
		margin-bottom: 0.2rem;
	}
}

.cure-App11_ShowcaseA-subHeading {
	max-width: none;
	text-align: center;
	margin: 0;
	@include minWidth('medium') {
		font-size: $a2;
	}
	@include minWidth('xxx-large') {
		font-size: $b4;
	}
	@include maxWidth('medium') {
		font-size: $a1;
	}
}