@use 'sass:math';
@import '../../../styles/variables';

.cure-IndexBody {
  height: calc(100% - #{$headerHeight});
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  @include maxWidth('x-small') {
    height: calc(100% - #{$headerHeight_x-small});
  }
}

.cure-IndexBody > .cure-CenteredWrap {
  flex: 1;
}

.cure-IndexBody-content {
  padding: m(3) $indexScreenHorizontalPadding m(3) $indexScreenHorizontalPadding;
  height: 100%;

  @include maxWidth('xx-large') {
    padding: m(2) $indexScreenHorizontalPadding m(2) $indexScreenHorizontalPadding;
  }

  @include maxWidth('large') {
    padding: 0;
  }
}

