@use 'sass:math';
@import '../../../styles/variables';

.cure-BarChart-chart {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.cure-BarChart-chartWrapper {
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.cure-BarChart-tooltip {
	background-color: rgb(41, 41, 41);
	padding: 0.2rem 0.5rem 0.2rem 0.5rem;
	@include maxWidth('x-small') {
		padding: 0.3rem;
		font-size: $b1;
	}
}

.cure-BarChart-chartPlaceholder {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
}