@use 'sass:math';
@import '../../../../../styles/variables';

.cure-CityInfoPanel {
	width: 0;
	height: 0;
	box-shadow: 3px 0 3px rgb(0 0 0 / 15%);
	z-index: 99;
	transition: 0.4s;
	display: flex;
	flex-direction: column;
	overflow: auto;
	order: 1;
	background: var(--base20);
	@include maxWidth('small') {
		order: 2;
		box-shadow: none;
		height: 20rem;
	  }
}

.cure-CityInfoPanel-header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.cure-CityInfoPanel-headerTitle {
	color: var(--base85);
	font-size: $a2;
	margin-bottom: m(math.div(1,3));
	padding: 0 m(1);
}

.cure-CityInfoPanel-headerIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 1rem;
}

.cure-CityInfoPanel p {
	padding: 0 m(1);
}

.cure-CityInfoPanel-list {
	margin-left: m(math.div(5,3));
}

.cure-CityInfoPanel-selectCity {
	font-size: $a1;
	width: fit-content;
	cursor: pointer;
}

.cure-CityInfoPanel-selectCityBtn {
	border: none;
	background-color: transparent;
	color: var(--accent50);
	text-decoration: underline;
	font-weight: 600;
	line-height: 1.5;
	cursor: pointer;
}

.cure-CityInfoPanel-selectCity:hover, .cure-CityInfoPanel-selectCityBtn:hover {
	color: var(--accent40);
}