@use 'sass:math';
@import '../../../../../styles/variables';

.cure-login-btn{
  border: none;
  text-decoration: underline;
  background: none;
  color: var(--accent60);
  cursor: pointer;
  height: 1rem;
  text-align: center;

  &:hover, &:focus{
    color:var(--accent40);
  }
}