@use 'sass:math';
@import '../../../../styles/variables';

.cure-ApplicationStoryNav {
  padding: .5rem 1rem 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;

  @include maxWidth('x-small') {
    padding: .15rem .5rem 0;
  }
}

.cure-ApplicationStoryNav-link, .cure-ApplicationStoryNav-link:visited {
  display: flex;
  color: $baseTextColor;
  text-decoration: none;
}

.cure-ApplicationStoryNav-link {
  margin-right: .5rem;
  @include maxWidth('large') {
    display: none;
  }
}

.cure-ApplicationStoryNav-linkTitle {
  margin-left: 5px;
  font-size: $a1;
  font-family: "Raleway", "Roboto", sans-serif;
  font-weight: 600;
}

.cure-ApplicationStoryNav-divider {
  color: var(--base35);
  margin: 0 .5rem;
  font-size: $b2;
  font-weight: 300;

  @include maxWidth('small') {
    margin: 0;
  }
}

.cure-ApplicationStoryNav .cure-ApplicationStoryNav-divider:first-of-type {
  @include maxWidth('large') {
    display: none;
  }
}