@use 'sass:math';
@import '../../styles/variables';

.cure-Story-InfoBox {
	font-weight: 600;
	margin: 0 0 0.8rem 0;
}

.cure-Story-smallSymbolBottom {
	font-size: 0.6rem;
	vertical-align: bottom;
}

.cure-Story-smallSymbolTop {
	font-size: 0.6rem;
	vertical-align: top;
}

.cure-Story-listLinks {
	margin-left: 1rem;
}

.horizon2020CureAppOverview .ptr-StoryMainPanelFooter-cure {
	background-image: linear-gradient(var(--base10), var(--base20));
}

.horizon2020CureAppOverview .ptr-dark .ptr-StoryMainPanelFooter-cure {
	background-image: linear-gradient(var(--base20), var(--base10));
}

.horizon2020CureAppOverview .ptr-StoryMainPanelFooter-cure a {
	width: auto;
	height: auto;
	text-decoration: underline;
	margin: 0;
	box-shadow: none;
	text-align: center;
	cursor: pointer;
	font-size: $b2;

	@include maxWidth('small') {
		font-size: $a1;
	}
}

.cure-Story-mainPanel-text {
	color: white;
}

.cure-Story-mainPanel-footer-tour {
	overflow-y: auto;
}