@use 'sass:math';
@import '../../../../styles/variables';

// Map set info
.cure-MapSetInfo {
  position: absolute;
  left: .7rem;
  bottom: .5rem;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  z-index: 3;
}

.cure-MapSetInfo > .ptr-MapScale {
  position: relative;
  left: auto;
  bottom: auto;
  z-index: 1;
}

.cure-MapSetInfo > .ptr-MapScale .ptr-MapScale-content {
  border: 1px solid var(--base50);
  border-top-width: 0;
  background: none;
}


.cure-MapSetInfo > .ptr-MapScale .ptr-MapScale-content span {
  font-size: $a0;
  color: var(--base50);
}