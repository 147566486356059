@import '../../../styles/variables';

.ptr-FeatureTooltip-content {
  background: var(--base0);
  max-width: 20rem;
  border-radius: .25rem;
  padding: .5rem;
  box-shadow: $floatingElementShadow;
  color: $baseTextColor;
  border: 1px solid var(--base30);
}

.ptr-FeatureTooltip-content .info {
  font-style: italic;
  color: var(--base50);
}