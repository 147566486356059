@use 'sass:math';
@import '../../../../../styles/variables';

.cure-App2_ShowcaseC_ChartTooltip-header {
  display: flex;
  align-items: center;
}
.cure-App2_ShowcaseC_ChartTooltip-header-icon {
  width: .85rem;
  height: .85rem;
  border-radius: .5rem;
  margin-right: .5rem;
}
.cure-App2_ShowcaseC_ChartTooltip-header-title {

}
.cure-App2_ShowcaseC_ChartTooltip-content {
  margin-top: .5rem;
}
.cure-App2_ShowcaseC_ChartTooltip-item {
  font-weight: bold;
}
.cure-App2_ShowcaseC_ChartTooltip-item > span {
  margin-right: .25rem;
}
.cure-App2_ShowcaseC_ChartTooltip-item.big {
  margin-bottom: .25rem;
}
.cure-App2_ShowcaseC_ChartTooltip-item.big,
.cure-App2_ShowcaseC_ChartTooltip-item.big > span {
  font-size: $a1;
}
.cure-App2_ShowcaseC_ChartTooltip-item,
.cure-App2_ShowcaseC_ChartTooltip-item > span {
  font-size: $b1;
}