@import '../../../../styles/variables';

.cure-LayerLabel {
  background: var(--base05);
}

// Content
.cure-LayerLabelBody {
  display: flex;
  flex-direction: column;
  background: var(--base05);
}