@use 'sass:math';
@import '../../../../styles/variables';

.cure-CatalogFilter {
  width: 20rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  position: sticky;
  top: 4.5rem;

  @include maxWidth('xx-large') {
    top: 3rem;
  }

  @include maxWidth('large') {
    background: var(--base15);
    width: 100%;
    padding: m(math.div(4,3));
    position: relative;
    top: auto;
  }

  @include maxWidth('medium') {
    margin-bottom: m(math.div(2,3));
    grid-gap: .75rem;
    padding: m(1) m(math.div(2,3));
  }

  @include maxWidth('x-small') {
    min-width: auto;
  }
}

.cure-Tag {
  color: $baseTextColor;
}

