@use 'sass:math';
@import '../../../../styles/variables';

.cure-MapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.cure-MapWrapper:after {
  content: "";
  border: .15rem dashed transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cure-MapWrapper.one-map.active:after,
.cure-MapWrapper.not-highlight-active.active:after {
  border: 0;
}

.cure-MapWrapper.active:after {
  border: .15rem dashed var(--accent50);
  z-index: 1;
}

// Map Tools
.cure-MapTools {
  position: absolute;
  top: .7rem;
  right: .7rem;
  z-index: 2;
  display: flex;
  grid-gap: 1rem;
  flex-direction: row-reverse;
}

.cure-MapTools.is-left {
  left: .7rem;
  right: auto;
  flex-direction: row;
}