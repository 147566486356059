@use 'sass:math';
@import '../../../../styles/variables';

.cure-Hero {
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-size: contain;
  position: relative;
  margin-bottom: m(2);
  padding: 0 m(1);

  @include maxWidth('xx-large') {
    height: 30rem;
  }

  @include maxWidth('large') {
    height: 25rem;
  }

  @include maxWidth('medium') {
    margin-bottom: m(1);
  }

  @include maxWidth('small') {
    height: 20rem;
  }

  @include maxWidth('x-small') {
    height: 15rem;
  }
}

.cure-Hero > * {
  z-index: 1;
}

.cure-Hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 65, 56, 0.65);
  z-index: 0;
}

.cure-Hero h1 {
  font-size: $b5;
  font-weight: 700;
  color: rgb(240, 240, 240);
  font-family: "Raleway", "Roboto", sans-serif;

  @include maxWidth('large') {
    font-size: $a4;
  }

  @include maxWidth('small') {
    font-size: $b4;
  }

  @include maxWidth('x-small') {
    font-size: $b3;
  }
}

.cure-Hero p {
  font-size: $a2;
  font-weight: 700;
  color: rgba(220, 220, 220, 1);
  text-align: center;
  font-family: "Raleway", "Roboto", sans-serif;

  @include maxWidth('large') {
    font-size: $b2;
  }

  @include maxWidth('x-small') {
    font-size: $a1;
  }
}

.cure-Hero a {
  background: none;
  color: #97e2d5;
}