@use 'sass:math';
@import '../../../styles/variables';

.cure-CenteredWrap {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;

  //@include minWidth('xx-large') {
  //  max-width: 100rem;
  //}
}