@use 'sass:math';
@import '../../../../styles/variables';

.ptr-MapSetWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

