@use 'sass:math';
@import '../../styles/variables';

.cure-Footer {
  width: 100%;
  background: hsl(44.14deg, 2.03%, 23.2%);
  padding: m(math.div(4,3)) 0 m(1);
  display: flex;
  flex-flow: row;
  justify-content: center;
  @include minWidth('xx-large') {
	padding: m(2) 0;
  }
}

.cure-Footer-container-main {
  width: 90%;
  max-width: 100rem;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  grid-gap: 1rem;
}

.cure-Footer-container {
  width: 30%;
  text-align: center;
}

.cure-Footer-socialLinksContainer {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: center;
}

.cure-Footer-projectContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cure-Footer-projectWrapper {
  border-radius: 50px;
  height: 3rem;
  width: 3rem;
  margin: 5px;
  background-color: white;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cure-Footer-projectWrapper:hover {
  background-color: hsl(40.72deg, 3.78%, 84.8%);
}

.cure-Footer-projectImg {
  width: 2rem;
  height: 2rem;
}

.cure-Footer-projectTitle {
  font-size: 1rem;
  margin: 0 0 0 -0.15rem;
  font-weight: 600;
  font-family: "Raleway", "Roboto", sans-serif;
  color: hsl(43.44deg, 1.46%, 34.24%);
}

.cure-Footer-projectLink {
  text-decoration: none;
}

.social-link {
  width: 3rem;
  height: 3rem;
  margin: 5px;
  color: white;
  background-color: hsl(170.77deg, 82.28%, 30.98%);
}

.social-link:hover {
  color: white;
  background: hsl(170.93deg, 83.5%, 40.39%);
}

.ptr-dark .social-link:hover {
  color: white;
  background: hsl(170.93deg, 83.5%, 40.39%);
}

.social-link .ptr-react-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.cure-Footer-euImg {
  width: 5rem;
}

.cure-Footer-policyText {
  color: hsl(40.99deg, 2.78%, 79.5%);
  margin: 0;
  font-size: $b1;
}

.cure-Footer-policyLink {
  font-weight: 600;
}

.cure-Footer-policyLink:hover {
  color: hsl(170.77deg, 82.28%, 30.98%);
}

.cure-Footer-policyLink:visited {
  color: hsl(170.77deg, 82.28%, 30.98%);
}

.cure-Footer-scrollToTopWrapper {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: end;
}

.scrollToTop-btn {
  background-color: hsl(170.77deg, 82.28%, 30.98%);
  cursor: pointer;
}

.scrollToTop-btn:hover {
  background-color: hsl(170.93deg, 83.5%, 40.39%);
}

.ptr-dark .scrollToTop-btn:hover {
  background-color: hsl(170.93deg, 83.5%, 40.39%);
}

.scrollToTop-btn .ptr-icon {
  filter: invert(100%);
}

@media (max-width: 1200px) {
  .cure-Footer-projectWrapper {
    height: 2.5rem;
    width: 2.5rem;
  }
  .cure-Footer-projectImg {
    width: 2rem;
    height: 2rem;
  }
  .cure-Footer-projectTitle {
    font-size: 0.8rem;
  }
  .social-link {
    width: 2.5rem;
    height: 2.5rem;
  }
  .social-link .ptr-react-icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}

@media (max-width: 900px) {
  .cure-Footer {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .cure-Footer-container-main {
    flex-flow: column;
    margin-left: 0%;
  }
  .cure-Footer-container {
    margin: 10px;
    width: 80%;
  }
  .cure-Footer-scrollToTopWrapper {
    width: 100%;
  }

  // CityDemonstrations
  .cure-Footer.is-cityDemonstrations {
    height: fit-content;
	overflow: auto;
	justify-content: unset;
  }
}

// AppStory

.cure-Footer-scrollToTopWrapper.is-AppStory,
.cure-Footer-scrollToTopWrapper.is-cityDemonstrations,
.cure-Footer-scrollToTopWrapper.is-cityDemonstrations-smallScreen {
	display: none;
}

.cure-Footer.is-AppStory {
  margin: 2rem 0 0 0;
  padding: 1rem 0 1rem 0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.cure-Footer-container-main.is-AppStory {
  flex-flow: column;
  margin-left: 0;
}

.cure-Footer-container.is-AppStory {
  margin: 10px;
  width: 80%;
}

.ptr-dark .cure-Footer.is-AppStory {
  background: hsl(38.14deg, 0.85%, 15.16%);
}

// City demonstrations
.cure-Footer.is-cityDemonstrations {
	height: 8rem;
	padding: 0.8rem;
	@include maxWidth('small') {
		display: none;
	}
}

.cure-Footer-container-main.is-cityDemonstrations {
	width: 100%;
	margin-left: 0;
}

.cure-Footer-container.is-cityDemonstrations {
	width: 32%;
	min-width: 10rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.cure-Footer-projectWrapper.is-cityDemonstrations {
	height: 2.5rem;
	width: 2.5rem;
}

.cure-Footer-projectImg.is-cityDemonstrations {
	height: 2rem;
	width: 2rem;
}

.cure-Footer-projectTitle.is-cityDemonstrations {
	font-size: 0.8rem;
}

.ptr-IconTool.social-link.is-cityDemonstrations {
	width: 2.5rem;
	height: 2.5rem;
}

.social-link.is-cityDemonstrations .ptr-react-icon {
    width: 1.2rem;
    height: 1.2rem;
  }

.cure-Footer-euImg.is-cityDemonstrations {
	width: 6.5rem;
	height: 5.8rem;
}

.cure-Footer-policyText.is-cityDemonstrations {
	max-width: 17rem;
}

.cure-Footer.is-cityDemonstrations-smallScreen {
	padding: 1rem;
    margin-top: m(1);
}