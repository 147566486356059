@use 'sass:math';
@import '~@gisatcz/ptr-core/lib/styles/_variables';

.ptr-simple-layers-control {
	position: absolute;
	z-index: 999;
	display: flex;
	flex-direction: column;
	background: rgba(255, 255, 255, 0.8);
	border-radius: 0.3rem;
	box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.3);
	margin-bottom: m(math.div(1, 3));
	width: 2rem;
	height: 2rem;
}

.ptr-simple-layers-control.control > .ptr-button:focus {
	outline: none;
}

.ptr-simple-layers-control.control > .ptr-button:focus::after {
	box-shadow: none;
	border: 0;
}

.ptr-simple-layers-control.control > .ptr-button {
	padding: 0;
	background: none;
	border: 0;
	border-radius: 0.3rem;
	box-shadow: none;
	user-select: none;
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 1.125rem;
	color: var(--base50);
	cursor: pointer;

	transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}

.ptr-simple-layers-control.control .ptr-icon {
	width: 1.35rem;
	height: 1.35rem;
}

.ptr-simple-layers-control.control > .ptr-button:hover {
	background-color: rgba(255, 255, 255, 1);
	color: var(--accent50);
}

.ptr-simple-layers-control.control > .ptr-button:not(.ghost):not(.invisible):not(.primary):not(.inverted):hover:after {
	background: none;
}

.ptr-simple-layers-control-menu {
	position: absolute;
	border-radius: 0.25rem;
	width: 0;
	background: var(--base05);
	transition: width 0.15s ease-in-out 0.15s, height 0.15s ease-in-out, border-radius 0s;
	filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.2));
}

.ptr-simple-layers-control-menu-content {
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
}

.ptr-simple-layers-control-menu.open {
	transition: width 0.15s ease-in-out, height 0.15s ease-in-out 0.15s, border-radius 0s ease-in-out 0.3s;
}

.ptr-simple-layers-control-menu:after {
	content: '';
	position: absolute;
	bottom: 0;
	border-bottom-color: transparent;
	border-bottom-style: solid;
	border-bottom-width: 0;
	border-top-color: transparent;
	border-top-style: solid;
	border-top-width: 0;
}

.ptr-simple-layers-control-menu.open:after {
	border-bottom-width: 1rem;
	border-top-width: 1rem;
}

/* Menu on left */
.ptr-simple-layers-control-menu.left {
	right: calc(100% + 1.5rem);
	bottom: 0;
	border-bottom-right-radius: 0;
}

.ptr-simple-layers-control-menu.left:not(.open) {
	border-top-right-radius: 0;
}

.ptr-simple-layers-control-menu.left:after {
	border-left-color: var(--base05);
	border-left-style: solid;
	border-left-width: 0;
	right: -1rem;
	transition: border-bottom-width 0s ease-in-out 0.3s, border-top-width 0s ease-in-out 0.3s,
		border-left-width 0s ease-in-out 0.3s;
}

.ptr-simple-layers-control-menu.left.open:after {
	border-left-width: 1rem;
	transition: border-bottom-width 0s, border-top-width 0s, border-left-width 0s;
}

/* Menu on right */
.ptr-simple-layers-control-menu.right {
	left: calc(100% + 1.5rem);
	bottom: 0;
	border-bottom-left-radius: 0;
}

.ptr-simple-layers-control-menu.right:not(.open) {
	border-top-left-radius: 0;
}

.ptr-simple-layers-control-menu.right:after {
	border-right-color: var(--base05);
	border-right-style: solid;
	border-right-width: 0;
	left: -1rem;
	transition: border-bottom-width 0s ease-in-out 0.3s, border-top-width 0s ease-in-out 0.3s,
		border-right-width 0s ease-in-out 0.3s;
}

.ptr-simple-layers-control-menu.right.open:after {
	border-right-width: 1rem;
	transition: border-bottom-width 0s, border-top-width 0s, border-right-width 0s;
}

/* Menu on bottom */
.ptr-simple-layers-control-menu.bottom {
	top: 0;
	bottom: auto;
}
.ptr-simple-layers-control-menu.bottom:after {
	top: 0;
	bottom: auto;
}
.ptr-simple-layers-control-menu.bottom.right {
	border-radius: 0 0.25rem 0.25rem 0.25rem;
}
.ptr-simple-layers-control-menu.bottom.right:not(.open) {
	border-radius: 0 0.25rem 0.25rem 0;
}
.ptr-simple-layers-control-menu.bottom.left {
	border-radius: 0.25rem 0 0.25rem 0.25rem;
}
.ptr-simple-layers-control-menu.bottom.left:not(.open) {
	border-radius: 0.25rem 0 0 0.25rem;
}

/* Layer tile */
.ptr-simple-layers-control-tile {
	background-image: none;
	background-size: cover;
	border: 0;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	cursor: pointer;
}

.ptr-simple-layers-control-tile.active {
	border: 0;
}

.ptr-simple-layers-control-tile-name {
	color: var(--base50);
	background: inherit;
	font-size: $b1;
	width: 100%;
	text-align: center;
	padding: 0.25rem 0 0 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ptr-simple-layers-control-tile.active .ptr-simple-layers-control-tile-name {
	background: inherit;
	color: var(--accent50);
	font-weight: bold;
}

.ptr-simple-layers-control-tile-preview {
	border-radius: 0.15rem;
	box-shadow: 0 0 0 2px var(--base50);
	object-fit: cover;
	align-self: stretch;
}

.ptr-simple-layers-control-tile.active .ptr-simple-layers-control-tile-preview {
	box-shadow: 0 0 0 3px var(--accent50);
}

.cure-UrbanAtlasLegend {
	height: 8rem;
	width: 100%;
	padding-top: .25rem;
	overflow-y: auto;
}

.cure-UrbanAtlasLegend-item {
	display: flex;
	align-items: center;
	line-height: 1.25;
}

.cure-UrbanAtlasLegend-color {
	min-width: 1rem;
	height: .5rem;
	margin-right: .25rem;
}

.cure-UrbanAtlasLegend-name {
	color: var(--base70);
	font-size: $a0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
