@use 'sass:math';
@import '../../../styles/variables';

.cure-HeaderTools {
  display: flex;
  height: 100%;
  padding: .5rem 0;

  @include maxWidth('small') {
    padding: .55rem 0;
  }
}

.cure-HeaderTools .ptr-IconTool {
  @include maxWidth('x-small') {
    width: 1.75rem;
    height: 1.75rem;
  }
}