@use 'sass:math';
@import '../../../styles/variables';

.cure-Table-container {
	width: 100%;
	height: 100%;
}


.iyJMSp {
	height: 100%;
}

.leDhON {
	height: 100%;
}