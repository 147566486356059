@use 'sass:math';
@import '../../../../../styles/variables';

.cure-App5_ShowcaseB-BarChart {
	width: 100%;
	height: 30rem;
	margin-bottom: 10rem;
}

.cure-App5_ShowcaseB-BarChartTitle {
	margin: 0;
	text-align: center;
	font-size: $b2;
	font-weight: 600;
}

.cure-App5_ShowcaseB-InfoWindowTitle {
	margin: 0 0 0.5rem 0;
}

.cure-App5_ShowcaseB-InfoWindowParagraph {
	margin: 0;
}