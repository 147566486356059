@use 'sass:math';
@import '../../../../styles/variables';

$width: 10rem;

.cure-CenteredOptions {
  position: absolute;
  top: .7rem;
  left: calc(50% - $width/2);
  z-index: 10;
  display: flex;
  flex-direction: column;
  grid-gap: .7rem;
  align-items: center;
  width: $width;
}

