@use 'sass:math';
@import '../../../../styles/variables';

.cure-UserMenu {
  right: 0;
  box-shadow: $floatingElementShadow;
}

.cure-UserMenu .cure-Username .ptr-LayerLabelTool-title{
  font-weight: 700;
}
