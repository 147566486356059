@use 'sass:math';
@import '../../../styles/variables';

.ptr-ChartTooltip {
  background: var(--base0);
  padding: .5rem;
  border-radius: .25rem;
  box-shadow: $floatingElementShadow;
  color: var(--base85);
}

