@use 'sass:math';
@import '../../../../styles/variables';

// Map set controls
.cure-MapSetControls {
  position: absolute;
  right: .7rem;
  bottom: .7rem;
  grid-gap: .5rem;
  display: flex;
  flex-direction: column;
  z-index: 9;
}

.cure-MapSetControls.is-horizontal {
  flex-direction: row;
}

.cure-MapSetControls > .ptr-map-controls {
  position: relative;
  bottom: auto;
  right: auto;
}

// Zoom controls
.cure-MapSetControls .zoom-control.control {
  background: var(--base05);
  box-shadow: $floatingElementShadow;
  display: flex;
  flex-direction: column;
}

.cure-MapSetControls.is-horizontal .zoom-control.control {
  flex-direction: row-reverse;
}

.ptr-dark .cure-MapSetControls .zoom-control.control {
  border: .05rem solid var(--base30);
}

.cure-MapSetControls .zoom-control.control .ptr-button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  background: var(--base05);
  width: $iconToolSize_medium;
  height: $iconToolSize_medium;
}

.cure-MapSetControls .zoom-control.control {
  border-radius: math.div($iconToolSize_medium,2);
}

.cure-MapSetControls .zoom-control.control .ptr-button:hover {
  background: $hoverBackgroundColor;
}

.ptr-dark .cure-MapSetControls .zoom-control.control .ptr-button:hover {
  background: $hoverBackgroundColor-dark;
}

.cure-MapSetControls.is-horizontal .ptr-map-controls .control > .ptr-button:not(:first-child) {
  border: 0;
}

.cure-MapSetControls .zoom-control.control .ptr-button:first-child,
.cure-MapSetControls .zoom-control.control .ptr-button:first-child:hover {
  border-radius: math.div($iconToolSize_medium,2) math.div($iconToolSize_medium,2) 0 0;
}

.cure-MapSetControls.is-horizontal .zoom-control.control .ptr-button:first-child,
.cure-MapSetControls.is-horizontal .zoom-control.control .ptr-button:first-child:hover {
  border-radius: 0 math.div($iconToolSize_medium,2) math.div($iconToolSize_medium,2) 0;
}

.cure-MapSetControls .zoom-control.control .ptr-button:last-child,
.cure-MapSetControls .zoom-control.control .ptr-button:last-child:hover {
  border-radius: 0 0 math.div($iconToolSize_medium,2) math.div($iconToolSize_medium,2);
}

.cure-MapSetControls.is-horizontal .zoom-control.control .ptr-button:last-child,
.cure-MapSetControls.is-horizontal .zoom-control.control .ptr-button:last-child:hover {
  border-radius: math.div($iconToolSize_medium,2) 0 0 math.div($iconToolSize_medium,2);
}

.cure-MapSetControls .zoom-control.control .ptr-button > .ptr-icon {
  width: $iconSize;
  height: $iconSize;
  color: $secondaryIconColor;
}

.cure-MapSetControls .zoom-control.control .ptr-button:first-child > .ptr-icon {
  margin-top: .5rem;
}

.cure-MapSetControls.is-horizontal .zoom-control.control .ptr-button:first-child > .ptr-icon {
  margin-top: .35rem;
}

.cure-MapSetControls .zoom-control.control .ptr-button:last-child > .ptr-icon {
  margin-bottom: .5rem;
}

.cure-MapSetControls.is-horizontal .zoom-control.control .ptr-button:last-child > .ptr-icon {
  margin-bottom: .35rem;
}

.cure-MapSetControls .zoom-control.control .ptr-button:hover > .ptr-icon {
  color: $baseIconColor;
}