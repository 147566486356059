@use 'sass:math';
@import '../../../../../styles/variables';

.cure-AppStoryHeaderSelect {
  color: var(--base80);
}

.cure-AppStoryHeaderSelect > div {
  @include maxWidth('small') {
    padding: 3px 0;
  }
}

.cure-AppStoryHeaderSelect svg {
  width: 14px;
}