@use 'sass:math';
@import '../../../../styles/variables';

.cure-About .cure-Hero {
  background-image: url('../../../../assets/img/intro_dark.webp');
}

.cure-About-content {
  margin: 0 auto m(3);
  max-width: 50rem;
  position: relative;
  z-index: 1;

  @include maxWidth('medium') {
    padding: 0 m(math.div(4,3)) 0 m(math.div(2,3));
    margin: 0 auto 2rem;
  }
}

.cure-About ul {
  margin: 0 0 1rem 1rem;
}

.cure-About a {
  font-weight: bold;
  background-color: var(--accent10);
}

.cure-About p,
.cure-About ul li {
  font-size: $a1;
  color: $secondaryTextColor;
}

.cure-About-logos {
  display: flex;
  flex-wrap: wrap;
  grid-gap: m(3);
  justify-content: center;
  align-items: center;
  margin: m(1) 0 m(3);

  @include maxWidth('x-small') {
    grid-gap: m(1);
  }
}

.cure-About-logos a {
  background-color: transparent;
}

.cure-About-logos img {
  max-width: 10rem;
  max-height: 5rem;
}