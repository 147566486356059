@use 'sass:math';
@import '../../styles/variables';

.cure-Header {
  height: $headerHeight;
  width: 100%;
  border-bottom: 1px dotted var(--base25);
  position: relative;
  z-index: 20;

  @include maxWidth('x-small') {
    height: $headerHeight_x-small;
  }
}

.cure-Header > div {
  height: 100%;
}

.cure-Header-content {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 $indexScreenHorizontalPadding;
  display: flex;
  justify-content: space-between;

  @include maxWidth('medium') {
    padding: 0 1rem;
  }

  @include maxWidth('small') {
    padding: 0 .5rem 0 1rem;
  }
}

.cure-Header.is-wide .cure-Header-content {
  padding: 0 1rem 0 calc(#{$appStoryNavPanelWidth} - 3px);

  @include maxWidth('medium') {
    padding: 0 1rem;
  }

  @include maxWidth('small') {
    padding: 0 .5rem 0 1rem;
  }
}

.cure-TitleWrapper {
  display: flex;
  align-items: flex-end;
  padding: .35rem 0;
  min-width: fit-content;
  text-decoration: none;

  @include maxWidth('small') {
    padding: .4rem 0;
  }

  @include maxWidth('x-small') {
    max-width: 5rem;
  }
}

.cure-Title {
  display: block;
  font-family: "Raleway" ,"Roboto", sans-serif;
  font-weight: 600;
  margin: 0 0 0 -.15rem;
  padding: 0;
  color: $secondaryTextColor;
  font-size: $b2;
  line-height: 1;
  text-transform: uppercase;

  @include maxWidth('medium') {
    font-size: $a1;
  }

  @include maxWidth('x-small') {
    display: none;
  }
}

.cure-Title::first-letter {
  font-size: 0;
}

.cure-Logo {
  height: 100%;
}