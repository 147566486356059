@use 'sass:math';
@import '../../../../styles/variables';

.cure-CityDemonstrations {
	overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
	position: relative;
  @include maxWidth('small') {
	flex-direction: column;
  }
}

.cure-CityDemonstration-map {
	background-image:  linear-gradient(var(--base20) 1px, transparent 1px), linear-gradient(to right, var(--base20) 1px, transparent 1px);
	background-size: 20px 20px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	order: 2;
	position: absolute;
	@include maxWidth('small') {
		order: 1;
		box-shadow: 0 3px 3px 0 rgb(0 0 0 / 15%);
		position: relative;
	  }
}

.cure-CityDemonstrations-Tooltip {
	background: rgba(0, 0, 0, .7);
	color: rgb(210, 210, 210);
	padding: .35rem .75rem;
	border-radius: .25rem;
	font-size: $a1;
	font-weight: 700;
	width: fit-content;
}

.cure-CityDemonstration-map .cure-MapSetInfo {
	left: auto;
	right: 4rem;
}

