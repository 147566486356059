@use 'sass:math';
@import '../../../../../styles/variables';

// Header
.cure-LayerLabelHeader {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-size: $b1;
  min-width: 10rem;
  line-height: 1.25;
  padding: .15rem 0;
  @include maxWidth('x-small') {
    font-size: $b2;
  }
}

.cure-LayerLabelHeader-title {
  display: flex;
  align-items: center;
  color: $baseTextColor;
  font-weight: 600;
  padding: .1rem 1rem .1rem .5rem;
  width: 100%;
  @include maxWidth('small') {
    font-size: $a1;
  }
  @include maxWidth('x-small') {
    font-size: $b1;
  }
}

.cure-LayerLabelHeader-info{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $baseTextColor;
  padding: 0rem 1rem 0 .5rem;
  font-size: .7rem;
  width: 100%;
}

.cure-LayerLabelHeader-info, .cure-LayerLabelHeader-info p{
  display: flex;
  align-items: center;
  color: $baseTextColor;
  padding-right: 0.1rem;
  margin-bottom: 0rem;
  font-size: .65rem;

  :not(:first-child) {
    &::before{
      content: '|';
      margin-right: 0.1rem;
    }
  }
}


.ptr-ExpandableLabelHeader-controlButton.ptr-IconTool {
  height: unset;
}

.ptr-ExpandableLabelHeader-content {
  display: flex;
  align-items: center;
  @include maxWidth('small') {
    width: 6rem;
  }
}