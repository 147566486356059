@use 'sass:math';
@import '../../../../../styles/variables';

.cure-StorySelect {
  @include maxWidth('medium') {
    max-width: 18rem;
  }

  @include maxWidth('small') {
    max-width: 10rem;
  }
}