@use 'sass:math';
@import '../../../../styles/variables';

.cure-LoginModal {
  max-width: 90%;
  width: 25rem;
}

.cure-LoginForm {
  background-color: white;
  padding: 3rem 3.25rem 3.25rem 3rem;
}

.cure-LoginForm-title {
  h3 {
    font-size: $b2;
    margin: 0 0 m(1);
    display: flex;
    align-items: center;
    grid-gap: .25rem;
  }
}

.cure-LoginForm-title.has-icon {
  margin-left: -2.5rem;
}

.cure-InputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: m(math.div(2,3));
  font-size: $a1;
}

.cure-InputContainer label,
.ptr-input-text input {
  font-size: $a1;
}

.cure-InputContainer .ptr-input-text.focus {
  box-shadow: 0 0 3px var(--base50);
}
.ptr-input-text.valid{
  border-color: var(--accent50);
  background: var(--accent05);
}

.ptr-input-text.invalid{
  border-color: #c74c4c;
  background: #fff6f6
}

.cure-ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: m(1);
  grid-gap: m(math.div(1,3));
}

button[type="submit"] {
  font-size: $a1;
  min-width: 10rem;
}

button[type="submit"]:disabled {
  background: var(--base20);
  pointer-events: none;
}

.error {
  color: red;
  font-size: $a1;
  margin-top: m(math.div(2,3))
}