@use 'sass:math';
@import '../../../../../styles/variables';
@import '~@gisatcz/visat-components/lib/styles/_placeholderAnimation';

.cure-CatalogFilterCategorySection {
  padding-left: 0;
}

.cure-CatalogFilterCategorySection-title {
  position: relative;
  overflow: hidden;
  font-size: $b1;
  font-family: "Raleway" ,"Roboto", sans-serif;
  font-weight: 600;
  letter-spacing: .5px;
  color: $secondaryTextColor;
  text-transform: uppercase;
}

.cure-CatalogFilterCategorySection-title.is-placeholder {
  background: $placeholderLightColor;
  width: 3rem;
  height: .7rem;
  border-radius: .5rem;
  margin-bottom: .5rem;
}

.cure-CatalogFilterCategorySection-title.is-placeholder::before {
  @include placeholderAnimation()
}

.cure-CatalogFilterCategorySection-body {
  margin-top: .2rem;
}

